import React, { forwardRef } from "react";
import { Button, ButtonProps } from "@repo/ui/components/ui/button";
import { cn } from "@repo/ui/lib/utils";
import { Loader2 } from "lucide-react";

export interface CustomButtonProps extends ButtonProps {
  isLoading?: boolean;
}

const CustomButton = forwardRef<HTMLButtonElement, CustomButtonProps>(
  (
    {
      type = "button",
      children,
      className,
      isLoading = false,
      disabled = false,
      ...props
    },
    ref
  ) => {
    return (
      <Button
        type={type}
        disabled={isLoading || disabled}
        className={cn(
          "min-w-[105px] min-h-12 py-3 rounded-2 hover:opacity-90 text-base",
          className
        )}
        ref={ref}
        {...props}
      >
        {isLoading ? <Loader2 className="mr-2 h-4 w-4 animate-spin" /> : null}
        {children}
      </Button>
    );
  }
);

CustomButton.displayName = "CustomButton";

export default CustomButton;
