import useConfirmModalStore from "@admin/store/useConfirmModalStore";
import CustomButton from "@repo/ui/components/common/CustomButton";
import {
  AlertDialog,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
} from "@ui/components/ui/alert-dialog";

const ConfirmModal = () => {
  const {
    isOpen,
    type,
    setOpen,
    hideButton,
    title,
    content,
    onConfirm,
    onCancel,
    closeModal,
    confirmText,
    isConfirmDisabled,
  } = useConfirmModalStore((state) => state);

  const handleConfirm = () => {
    if (onConfirm) onConfirm();
    closeModal();
  };

  const handleCancel = () => {
    if (onCancel) onCancel();
    closeModal();
  };

  return (
    <AlertDialog open={isOpen} onOpenChange={setOpen}>
      <AlertDialogContent className="max-h-[90vh] max-w-sm space-y-4 px-4 sm:rounded-2xl overflow-y-auto scrollbar-hide">
        <AlertDialogHeader>
          <div className="text-center text-xl font-bold">{title}</div>
          <div className="text-center text-sm text-Txt-Third">{content}</div>
        </AlertDialogHeader>
        {hideButton ? null : (
          <AlertDialogFooter>
            <CustomButton
              variant="outline"
              onClick={handleCancel}
              className="flex-1"
            >
              취소
            </CustomButton>
            <CustomButton
              variant={type}
              onClick={handleConfirm}
              className="flex-1"
              disabled={isConfirmDisabled}
            >
              {confirmText}
            </CustomButton>
          </AlertDialogFooter>
        )}
      </AlertDialogContent>
    </AlertDialog>
  );
};

export default ConfirmModal;
