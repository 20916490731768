"use client";

import Link from "next/link";
import { usePathname } from "next/navigation";
import React from "react";

import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@repo/ui/components/ui/accordion";
import { cn } from "@repo/ui/lib/utils";
import { Menu } from "@admin/constants/menu";
import { ChevronRight } from "lucide-react";

export interface SideNavigationProps {
  menus: Menu[];
}

const SideNavigationMenu: React.FC<SideNavigationProps> = ({ menus }) => {
  const pathname = usePathname();

  const currentOpenMenuHref = menus.find(({ href }) =>
    pathname.startsWith(href)
  )?.href;

  return (
    <aside className="h-full border-r border-r-Line-Gray-04 p-5 text-Body-2-R">
      <Accordion type="single" collapsible defaultValue={currentOpenMenuHref}>
        {menus.map(({ href, icon, label, children }) => (
          <AccordionItem value={href} key={label} className="border-none">
            {children ? (
              <>
                <AccordionTrigger
                  iconSize={20}
                  right
                  className={cn(
                    "flex w-full items-center justify-between rounded-2 stroke-Gray-04 px-4 py-3 font-normal text-Txt-Third hover:bg-ACW-Hover hover:stroke-Violet-01 hover:text-Txt-Violet active:bg-ACW-Pressed active:stroke-Violet-01 active:text-Txt-Violet",
                    {
                      "bg-ACW-Hover text-Txt-Violet": pathname.includes(href),
                    }
                  )}
                >
                  <div className="flex flex-row items-center gap-x-3">
                    {icon}
                    <p>{label}</p>
                  </div>
                </AccordionTrigger>
                <AccordionContent>
                  <ul>
                    {children.map((child) => (
                      <Link
                        href={`${href}${child.href}`}
                        key={child.label}
                        className={cn(
                          "flex bg-Gray-08 px-5 py-3 text-base text-Txt-Third hover:bg-ACW-Hover hover:text-Txt-Violet active:bg-ACW-Pressed active:text-Txt-Violet",
                          {
                            "!bg-ACW-Hover !text-Txt-Violet": pathname.includes(
                              `${href}${child.href}`
                            ),
                          }
                        )}
                      >
                        <li>{child.label}</li>
                      </Link>
                    ))}
                  </ul>
                </AccordionContent>
              </>
            ) : (
              <Link
                href={href}
                className={cn(
                  pathname.includes(href) && "!bg-ACW-Pressed !text-Txt-Violet",
                  "flex w-full items-center justify-between rounded-2 stroke-Gray-04 px-4 py-2.5 text-Txt-Third hover:bg-ACW-Hover hover:stroke-Violet-01 hover:text-Txt-Violet active:bg-ACW-Pressed active:stroke-Violet-01 active:text-Txt-Violet"
                )}
              >
                <div className="flex items-center gap-x-3">
                  {icon}
                  {label}
                </div>
                <ChevronRight
                  strokeWidth={1.5}
                  size={20}
                  className="shrink-0 text-Line-Gray-01 transition-transform duration-200"
                />
              </Link>
            )}
          </AccordionItem>
        ))}
      </Accordion>
    </aside>
  );
};

export default SideNavigationMenu;
