import { ReactNode } from "react";

import { create } from "zustand";

interface ConfirmModalState {
  type?: "default" | "destructive";
  isOpen: boolean;
  title: ReactNode;
  content: ReactNode;
  confirmText: string;
  isConfirmDisabled?: boolean;
  hideButton: boolean;
  setOpen: (isOpen: boolean) => void;
  onConfirm: (() => void) | null;
  onCancel: (() => void) | null;
  openModal: (options?: Partial<OpenModalOptions>) => void;
  closeModal: () => void;
  reset: () => void;
}

export interface OpenModalOptions {
  type?: "default" | "destructive";
  hideButton?: boolean;
  title: ReactNode;
  content: ReactNode;
  confirmText?: string;
  isConfirmDisabled?: boolean;
  onConfirm?: () => void;
  onCancel?: () => void;
}

const useConfirmModalStore = create<ConfirmModalState>((set) => ({
  type: "default",
  isOpen: false,
  title: "",
  content: "",
  confirmText: "확인",
  isConfirmDisabled: false,
  hideButton: false,
  onConfirm: null,
  onCancel: null,
  setOpen: (isOpen) => set({ isOpen }),
  openModal: (options) => set({ isOpen: true, ...options }),
  closeModal: () => {
    set({ type: "default", isOpen: false });

    // To prevent flickering
    setTimeout(() => {
      set({ title: "", content: "", onConfirm: null, onCancel: null });
    }, 100);
  },
  reset: () =>
    set({
      type: "default",
      isOpen: false,
      title: "",
      content: "",
      hideButton: false,
      isConfirmDisabled: false,
      confirmText: "확인",
      onConfirm: null,
      onCancel: null,
    }),
}));

export default useConfirmModalStore;
