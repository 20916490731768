"use client";

import React, { PropsWithChildren } from "react";

import ConfirmModal from "../modal/ConfirmModal";

const ModalProvider = ({ children }: PropsWithChildren) => {
  return (
    <>
      {children}
      <ConfirmModal />
    </>
  );
};

export default ModalProvider;
